:root {
  /* Background Colors */
  --bg-dark: #36454f;
  --bg-secondary: #f0f0f0;
  --bg-white: #ffffff;

  /* TODO: move colors and relabel them when we start utilizing them */
  --bg-darker: #082c3f;
  --bg-accent: #093e5e;
  --bg-neutral: #222222;

  /* Text Colors */
  --text-dark: #000000;
  --text-secondary: #A6AAA9;
  --text-white: #ffffff;

  --text-light: #34A5DA;
  --text-accent: #F96928;
  --text-neutral: #838787;
}

.scheme-bg-dark {
  background-color: var(--bg-dark) !important;
}

.scheme-text-dark {
  color: var(--text-dark) !important;
}

.scheme-bg-secondary {
  background-color: var(--bg-secondary) !important;
}

/* Mapbox CSS fixes */
.mapboxgl-ctrl-logo {
  height: 16px !important;
  width: 60px !important;
  background-size: 100% 100%;
}

body {
  overflow-x: hidden;
  max-height: 100%;
}

/* CKEditor CSS fixes */
.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: var(--ck-editor-index) !important;
}

/* styling for measurement labels */
.measurement-label {
  z-index: var(--main-canvas-index + 2);
  border-radius: 25px;
  padding: 3px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-family: "Helvetica Neue", sans-serif;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.7);
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}

@media (max-width: 576px) {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .modal-content {
    min-height: 100vh;
    border-radius: 0;
  }
}